import React, { Fragment, useContext, useEffect, useState } from "react";
import "../../index.css";
import styled from "@emotion/styled";
import { BankWrapper } from "../../components/BankWrapper";
import { BankNavigation } from "../../components/BankNavigation";
import { BankAccountNavigation } from "../../components/BankAccountNavigation";
import { SBNavOffsetContainer, StyledCard } from "../../styled";
import {
  StateContext,
  StateContextProvider,
} from "../../contexts/StateContext";
import {
  Button,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { BankInformationFormBody } from "../../components/BankInformationFormBody";

const SettingsCard = styled(StyledCard)`
  width: 100%;
  margin: 1rem;
  align-self: start;

  > * {
    margin-bottom: 1rem;
  }
`;

function BaseComponent() {
  const { state, setState } = useContext(StateContext);
  const [page, setPage] = useState("accounts");

  const onDelete = (index) => () => {
    setState({
      ...state,
      accounts: state.accounts.filter((...args) => args[1] !== index),
    });
  };

  useEffect(() => {
    setState((s) => ({
      ...s,
      accounts: [
        {
          routingNum: "2748957430",
          accountNum: "128894503",
          name: "Personal Checking (BoA)",
        },
      ],
    }));
  }, [setState]);

  return (
    <BankWrapper>
      <BankNavigation />
      <SBNavOffsetContainer
        style={{
          padding: 0,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <BankAccountNavigation />
        <SettingsCard>
          <Typography variant="h4">Connect External Accounts</Typography>
          <Typography variant="body1">
            You can link external accounts to transfer funds or to set up bill
            pay. If you want to set this up later, you can do so in the settings
            screen.
          </Typography>
          {page === "accounts" ? (
            <Fragment>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setPage("add-account")}
              >
                Add External Account
              </Button>
              {state.accounts && state.accounts.length ? (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Routing Number</TableCell>
                        <TableCell align="right">Account Number</TableCell>
                        <TableCell align="right">Remove</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.accounts.map((acc, index) => (
                        <TableRow key={acc.name}>
                          <TableCell component="th" scope="row">
                            {acc.name}
                          </TableCell>
                          <TableCell align="right">{acc.routingNum}</TableCell>
                          <TableCell align="right">{acc.accountNum}</TableCell>
                          <TableCell align="right">
                            <IconButton onClick={onDelete(index)}>
                              <Icon>delete</Icon>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}
            </Fragment>
          ) : null}
          {page === "add-account" ? (
            <BankInformationFormBody setPage={setPage} fireRequest />
          ) : null}
        </SettingsCard>
      </SBNavOffsetContainer>
    </BankWrapper>
  );
}

export default function Component() {
  return (
    <StateContextProvider>
      <BaseComponent />
    </StateContextProvider>
  );
}
